//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { FocusTrap } from 'focus-trap-vue';
import { Carousel, Slide } from '@fc/angie-ui';
import FCImage from '~/components/FCImage.vue';

Vue.component('FocusTrap', FocusTrap);
export default {
  name: 'AccessoryMultiLightbox',
  components: {
    FCImage,
    Carousel,
    Slide,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalActive: true,
    };
  },
  methods: {
    handleScroll() {
      const body = document.getElementsByTagName('BODY')[0];
      if (this.modalActive) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'scroll';
      }
    },
    closeModal() {
      this.modalActive = false;
      this.handleScroll();
      this.$emit('close');
    },
  },
  mounted() {
    this.handleScroll();
  },
  watch: {
    modalActive(_) {
      this.handleScroll();
    },
  },
  beforeDestroy() {
    this.modalActive = false;
  },
};
